import { Component, OnInit, Input, ViewEncapsulation  } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { routerTransition } from '../router.animations';
import { RestApiService } from '../shared/services/rest-api.service';
import { formatDate } from '@angular/common';

import { NgbModal, ModalDismissReasons, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    animations: [routerTransition()]
})

export class LoginComponent implements OnInit {

  closeResult: string;

    @Input() loginDetails = { email: '', password: '' }
    constructor(
        private translate: TranslateService,
        public restApi: RestApiService,
        public router: Router,
        private modalService: NgbModal
        ) {
            this.translate.addLangs(['en', 'fr']);
            this.translate.setDefaultLang('fr');
            const browserLang = this.translate.getBrowserLang();
            this.translate.use(browserLang.match(/en|fr/) ? browserLang : 'fr');
    }


    ngOnInit() {}


    onLoggedin() {
        localStorage.setItem('isLoggedin', 'true');
    }

    connect(dataUser) {
    this.restApi.putConnect(this.loginDetails).subscribe((data: {errorId,id}) => {
      if(data.errorId == 0 == true) {
        //window.alert(data.id);
        localStorage.setItem('isLoggedin', 'true');
        sessionStorage.setItem('UserId', data.id);
        //window.alert(sessionStorage.getItem('UserId'));
        //window.alert(formatDate(new Date(), 'yyyy-MM-ddTHH:mm:ssZ', 'en'));

        this.router.navigate(['dashboard'])
      } else {
        window.alert("Erreur: Vérifiez vos informations de connexion");
      }

    })
    }

    open1(content1) {
      this.modalService
        .open(content1, { ariaLabelledBy: 'modal-basic-title' })
        /*.result.then(
          result => {
            this.closeResult = `Closed with: ${result}`;
          },
          reason => {
            this.closeResult = `Closed with: ${result}`;//this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
          }
        );*/
    }
    /*private getDismissReason(reason: any): string {
      if (reason === ModalDismissReasons.ESC) {
        return 'by pressing ESC';
      } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
        return 'by clicking on a backdrop';
      } else {
        return `with: ${reason}`;
      }
    }*/

}
