import { Component, OnInit } from '@angular/core';

import {Router, NavigationEnd} from '@angular/router';

import { filter } from 'rxjs/operators';

  // declare ga as a function to set and sent the events
declare var gtag


@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'angularGoogleAnalytics';
  constructor(public router: Router) {
    /*this.router.events.subscribe(event => {
      if(event instanceof NavigationEnd){

        console.log(event.urlAfterRedirects);
        gtag('config', 'G-CP97BYHBE3', {'page_path': event.urlAfterRedirects});
      }
    })*/
    const navEndEvent$ = router.events.pipe(
    filter(e => e instanceof NavigationEnd)
    );
    navEndEvent$.subscribe((e: NavigationEnd) => {
      gtag('config', 'G-CP97BYHBE3', {'page_title':e.urlAfterRedirects});
    });
  }

    ngOnInit() {
    }
}
